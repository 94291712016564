import React from 'react';
import { saveCalendarData } from "./FirebaseUtil";
import { dateToStr } from "./Util";


export function RecordForm({ isRecordEditMode, closeEditMode, setCalendarData, uid, selectedDate, selectedData, setSelectedData}) {
    const initCalendarData = {
        records: [
          { title: "", body: "" , isDone: false}
        ],
        stamps: [],
        memo: "",
        exercise: 0,
        constipation: 0,
        health: 0
      };

    const dialogClose = (event) => {
        closeEditMode();
    };

    const handleRecordDoneChange = (event) => {
        // 押されたアイコンの data-id 属性から、どのレコードがクリックされたのかを特定する
        const index = parseInt(event.currentTarget.dataset.id, 10);
      
        // selectedData のコピーを作成
        const updatedRecords = [...selectedData.records];
      
        // クリックされたレコードの isDone 状態をトグル
        updatedRecords[index].isDone = !updatedRecords[index].isDone;
      
        // 更新された selectedData を setSelectedData を使って状態を更新
        setSelectedData(prevState => ({
          ...prevState,
          records: updatedRecords,
        }));
    };

    const handleStampSwitch = (event) => {
        event.currentTarget.dataset.switch = event.currentTarget.dataset.switch === "true" ? "false" : "true";
    }

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        // records を取得し、それぞれの入力フィールドから title と body を取得する
        const records = Array.from(document.querySelectorAll('.record'))
            .map((recordEl, recordIndex) => {
                const title = recordEl.querySelector(`#title-${recordIndex}`).value;
                const body = recordEl.querySelector(`#body-${recordIndex}`).value;
    
                // isDone は元の selectedData.records から取得するが、undefined でないかをチェックする
                const isDone = selectedData.records[recordIndex]?.isDone ?? false; // isDone が undefined の場合 false に設定

                // title が空の場合は無視
                if (title === "") {
                    return null;
                }
                return { title, body, isDone };
            })
            .filter((record) => record !== null);
    
        // スタンプの状態を取得
        const stamps = Array.from(document.querySelectorAll('.selectStamp'))
            .map((stampEl, stampIndex) => {
                const data = stampEl.dataset.switch;
                const icontype = stampEl.dataset.icontype;
    
                if (data === "false") {
                    return null;
                } else {
                    return icontype;
                }
            })
            .filter((stamp) => stamp !== null);
    
        // メモの内容を取得
        const memo = document.getElementById('textareaMemo').value;
    
        // selectedData に保存する
        selectedData.stamps = stamps;
        selectedData.records = records;
        selectedData.memo = memo;
    
        // データを保存
        saveCalendarData(uid, dateToStr(selectedDate), selectedData);
        setCalendarData(selectedData);
    
        // 編集モードを閉じる
        closeEditMode();
    };
    

    const handleAddrecord = (event) => {
        setSelectedData(prevState => ({
            ...prevState,
            records: [...prevState.records, initCalendarData]
        }));
    };

    const handleDialogClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div>
            {isRecordEditMode ? (
                <div className="dayDataForm">
                    <div className="dialogBase">
                        <div className="dialog" onClick={handleDialogClick}>
                            <button className="dialogClose" onClick={dialogClose}>×</button>

                            <div className="date">{selectedDate.getFullYear()}/{selectedDate.getMonth() + 1}/{selectedDate.getDate()}</div>

                            <form data-date={dateToStr(selectedDate)}>
                                <div className="records">
                                    <div className="card">
                                        <div className="label"><i className="las la-check"></i> RECORD</div>
                                        {selectedData.records.map((record, recordIndex) => (
                                            <div key={recordIndex} className={`record ${record.isDone ? "isDone" : ""}`}>
                                                <i class="las la-check" onClick={handleRecordDoneChange} data-id={recordIndex}></i>
                                                <label htmlFor={`title-${recordIndex}`} autocomplete="off">Title</label>
                                                <input type="text" id={`title-${recordIndex}`} defaultValue={record.title} placeholder="xxx" autocomplete="off"/>
                                                <label htmlFor={`body-${recordIndex}`}>Body</label>
                                                <textarea className="recordBody" id={`body-${recordIndex}`} defaultValue={record.body} placeholder="xxx"/>
                                            </div>
                                        ))}
                                        <button type="button" className="addBtn" onClick={handleAddrecord}>+</button>
                                    </div>

                                    <div className="card">
                                        <div className="label"><i className="las la-file-alt"></i> MEMO</div>
                                        <textarea id="textareaMemo" placeholder="xxx" defaultValue={selectedData.memo} />
                                    </div>

                                </div>

                                <div className="card">
                                    <div className="stamps">
                                        {selectedData.allStamps.map((stamp, index) => (
                                            <i className={`selectStamp las la-${stamp.type}`} data-icontype={`${stamp.type}`} data-switch={`${stamp.isOn}`} key={index} onClick={handleStampSwitch}></i>
                                        ))}
                                    </div>
                                </div>
                                <div className="btns">
                                    <button type="button" className="cancelBtn" onClick={dialogClose}>Cancel</button>
                                    <button type="button" className="saveBtn" onClick={handleSave}>Save</button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}