// App.js 
//npm run build / npm start
import React, { useState, useEffect } from "react";
import { TodoList } from './TodoList';
import { Memo } from './Memo';
import { RecordForm } from './RecordForm';
import { buildCalendar } from "./calendar";
import 'normalize.css';
import "./App.css";

import { signInWithGoogle, signOut, onAuthStateChange, loadCalendarData, loadCalendarDatas, loadWeeklyMode, saveWeeklyMode } from "./FirebaseUtil";
import { dateToStr, stampTypes } from "./Util";

const App = () => {
  const initCalendarData = {
    records: [
      { title: "", body: "" , isDone: false }
    ],
    stamps: [],
    memo: "",
    exercise: 0,
    constipation: 0,
    health: 0
  };

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentCalendarDatas, setCurrentCalendarDatas] = useState({});

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedData, setSelectedData] = useState(initCalendarData);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRecordEditMode, setIsRecordEditMode] = useState(false);

  const [isWeeklyMode, setIsWeeklyMode] = useState(false); // 週表示モードの状態追加

  const [uid, setUid] = useState(null);
  const { weeks, weekDays, dateKeys } = buildCalendar(currentDate);

  useEffect(() => {

    console.log("useEffect");

    setCurrentYear(currentDate.getFullYear());
    setCurrentMonth(currentDate.getMonth());

    onAuthStateChange((user) => {
      if (user) {
        setIsLoggedIn(true);
        setUid(user.uid);

        // ウィークリーモードの状態をFirebaseからロード
        loadWeeklyMode(user.uid)
          .then((mode) => {
            if (mode != null) {
              setIsWeeklyMode(mode);
            }
          })
          .catch((error) => {
            console.error(error);
          });

      } else {
        setIsLoggedIn(false);
        setUid(null);
      }
    });

    if (isLoggedIn) {
      loadCalendarDatas(uid, dateKeys)
        .then((datas) => {
          setCurrentCalendarDatas(datas);
          console.log(datas);
        })
        .catch((error) => {
          console.error(error);
        });

    } else {

    }

  }, [currentDate, isLoggedIn]);

  const previousMonth = () => {
    const newDate = new Date(currentYear, currentMonth - 1);
    setCurrentDate(newDate);
    console.log("previousMonth");
  };

  const nextMonth = () => {
    const newDate = new Date(currentYear, currentMonth + 1);
    setCurrentDate(newDate);
    console.log("nextMonth");
  };

  const handleGoogleSignIn = () => {
    signInWithGoogle()
      .then((result) => {
        const user = result.user;
        setUid(result.user.uid);

        // Googleログイン成功時の処理
        console.log(user);
      })

      .catch((error) => {
        // エラー処理
        console.log(error);
      });
  };

  const handleLogout = () => {
    signOut();
  };

  const handleCloseEditMode = () => {
    setIsRecordEditMode(false);
    setSelectedData(null);
    setSelectedDate(null);
  };

  const handleSetCalendarData = (data) => {
    setCurrentCalendarDatas(prevState => {
      return {
        ...prevState,
        [dateToStr(selectedDate)]: data
      };
    });
  };

  const handleToggleWeeklyMode = () => {
    const newMode = !isWeeklyMode;
    setIsWeeklyMode(newMode);

    // ウィークリーモードの状態をFirebaseに保存
    saveWeeklyMode(uid, newMode)
      .then(() => {
        console.log("Weekly mode saved:", newMode);
      })
      .catch((error) => {
        console.error("Failed to save weekly mode:", error);
      });
  };

  function selectDay(event) {
    const date = new Date(event.currentTarget.dataset.date);

    setSelectedDate(date);

    loadCalendarData(uid, event.currentTarget.dataset.date)
      .then((data) => {
        let tempData = data;

        if (tempData == null) {
          tempData = initCalendarData;
        } else {
          //レコードがない場合１つ作成する
          if (tempData.records == null) {
            tempData.records = [{ title: "", body: "" , isDone: false }];
          }
        }

        //スタンプの登録形式を変更する
        const allStamps = [];
        stampTypes.forEach((type, index) => {
          let isIncluded = false;
          if (tempData.stamps != null) {
            isIncluded = tempData.stamps.includes(type);
          }
          const stamp = {
            type: type,
            isOn: isIncluded
          };
          allStamps.push(stamp);
        });
        tempData.allStamps = allStamps;

        setSelectedData(tempData);
        setIsRecordEditMode(true);
      });
  }

  return (
    <div className={isWeeklyMode ? "weeklyMode" : "dailyMode"}>
      {isLoggedIn ? (
        <div className="container">
          <div className="header">
            <button onClick={previousMonth}>back</button>
            <div className="currentMonth">
              {currentYear}/{currentMonth + 1}
            </div>
            <button onClick={nextMonth}>next</button>

            <button className="logoutBtn" onClick={handleLogout}>
              Logout
            </button>
          </div>

          <div className="calendar">
            <div className="week">
              {weekDays.map((weekDay, index) => (
                <div className="weekDay cell" key={index}>
                  {weekDay}
                  <span className="er">{index}</span>
                </div>
              ))}
            </div>
                        
            {weeks.map((week, weekIndex) => (
              <div className={`week ${week.isCurrentWeek ? "now-week" : ""}`} key={weekIndex}>
                <span className="er">{weekIndex}</span>
                {week.days.map((day) => (
                  <div
                    className={`cell day ${
                      day.isCurrentMonth ? "" : "not-current-month"
                    } ${day.isWeekEnd ? "weekend" : ""} ${day.isToday ? "today" : ""}`}
                    key={day.dayIndex}
                    onClick={selectDay}
                    data-date={dateToStr(day.date)}
                  >
                    <div className="cellInnner">
                      <div className="date">
                        {day.date.getDate()}
                        {currentCalendarDatas[dateToStr(day.date)] &&
                        currentCalendarDatas[dateToStr(day.date)].memo ? (
                          <i className="las la-file-alt"></i>
                        ) : (
                          <i></i>
                        )}
                      </div>

                      {currentCalendarDatas[dateToStr(day.date)] &&
                      currentCalendarDatas[dateToStr(day.date)].records ? (
                        <ul className="records">
                          {currentCalendarDatas[dateToStr(day.date)].records.map(
                            (record, index) => (
                              <li key={index} className={record.isDone ? "isDone" : ""}>
                                <i className="las la-check"></i>
                                {record.title}
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <div></div>
                      )}

                      {currentCalendarDatas[dateToStr(day.date)] &&
                      currentCalendarDatas[dateToStr(day.date)].stamps ? (
                        <div className="stamps">
                          {currentCalendarDatas[dateToStr(day.date)].stamps.map(
                            (stamp, index) => (
                              <i className={`stamp las la-${stamp}`} key={index}></i>
                            )
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <span className="er">{day.dayIndex}</span>
                    </div>
                  </div>
                ))}
              </div>
            ))}

          </div>

          <RecordForm
            isRecordEditMode={isRecordEditMode}
            closeEditMode={handleCloseEditMode}
            setCalendarData={handleSetCalendarData}
            uid={uid}
            selectedDate={selectedDate}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />

          <TodoList
            isLoggedIn={isLoggedIn}
            uid={uid}
            currentDate={currentDate}
          />

          <Memo
            isLoggedIn={isLoggedIn}
            uid={uid}
          />

          <button className="switchAppModeButton" onClick={handleToggleWeeklyMode}>
           {isWeeklyMode ? "Switch to Daily Mode" : "Switch to Weekly Mode"}
          </button>

        </div>


      ) : (
        <div>
          <div className="header">
            <div className="currentMonth">
              {currentYear}/{currentMonth + 1}
            </div>
          </div>
          <div className="logout">
            <img src="../images/logo.png" className="logo" alt="logo" />
            <button className="loginBtn" onClick={handleGoogleSignIn}>
              Google Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
