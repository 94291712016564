// firebase.js
// Firebase関連の機能を提供するユーティリティモジュール
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, get } from "firebase/database";

// Firebaseの設定情報
const firebaseConfig = {
    apiKey: "AIzaSyAtXcN6zyxvHeiP3JmnkjvCNYjezFSe0LE",
    authDomain: "mycal-e38fc.firebaseapp.com",
    databaseURL: "https://mycal-e38fc-default-rtdb.firebaseio.com/",
    projectId: "mycal-e38fc",
    storageBucket: "mycal-e38fc.appspot.com",
    messagingSenderId: "983583585645",
    appId: "1:983583585645:web:2b258147c004648d77d664"
};

// Firebaseアプリの初期化
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

// Googleアカウントを使用したサインイン処理を行う関数
export const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
};

// サインアウト処理を行う関数
export const signOut = () => {
    return auth.signOut();
};

// 認証状態の変化を監視する関数
// ユーザーの認証状態が変化するたびに指定されたコールバックを呼び出す
export const onAuthStateChange = (callback) => {
    return onAuthStateChanged(auth, callback);
};

// 指定の日付に対応するカレンダーデータを読み込む関数
export const loadCalendarData = (uid, date) => {
    const recordsRef = ref(database, `users/${uid}/calendar/${date}`);
    return get(recordsRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            console.log("No data available");
            return null;
        }
    }).catch((error) => {
        console.error(error);
        return null;
    });
};

// 複数の日付に対応するカレンダーデータをまとめて読み込む関数
export const loadCalendarDatas = (uid, dates) => {
    const tmpDatas = [];

    // 各日付のデータ参照を作成し、Promiseリストに追加
    for (let i = 0; i < dates.length; i++) {
        tmpDatas.push(get(ref(database, `users/${uid}/calendar/${dates[i]}`)));
    }

    // 全ての参照のデータ取得をPromise.allSettledで実行
    return Promise.allSettled(tmpDatas).then((results) => {
        const datas = {};

        results.forEach((result, i) => {
            if (result.status === "fulfilled") {
                const data = result.value.val();
                datas[dates[i]] = data;
            } else {
                datas[dates[i]] = null;
            }
        });

        return datas;
    });
};

// 指定の日付に対応するカレンダーデータを保存する関数
export const saveCalendarData = (uid, date, data) => {
    const dataRef = ref(database, `users/${uid}/calendar/${date}`);
    return set(dataRef, data).then(() => {
        console.log("saved");
        return true;
    }).catch((error) => {
        console.error(error);
        return false;
    });
};

// 指定の日付に対応するTODOデータを読み込む関数
export const loadTodoData = (uid, date) => {
    const recordsRef = ref(database, `users/${uid}/todo/${date}`);
    return get(recordsRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            console.log("No data available");
            return null;
        }
    }).catch((error) => {
        console.error(error);
        return null;
    });
};

// 指定の日付に対応するTODOデータを保存する関数
export const saveTodoData = (uid, date, data) => {
    const dataRef = ref(database, `users/${uid}/todo/${date}`);
    return set(dataRef, data).then(() => {
        console.log("saved");
        return true;
    }).catch((error) => {
        console.error(error);
        return false;
    });
};

// ユーザーのメモデータを読み込む関数
export const loadMemoData = (uid) => {
    const recordsRef = ref(database, `users/${uid}/memo`);
    return get(recordsRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            console.log("No memo data available");
            return null;
        }
    }).catch((error) => {
        console.error(error);
        return null;
    });
};

// ユーザーのメモデータを保存する関数
export const saveMemoData = (uid, data) => {
    const dataRef = ref(database, `users/${uid}/memo`);
    return set(dataRef, data).then(() => {
        console.log("Memo saved");
        return true;
    }).catch((error) => {
        console.error(error);
        return false;
    });
};

// ウイークリーモードのデータをロードする関数
export const loadWeeklyMode = (uid) => {
    const modeRef = ref(database, `users/${uid}/settings/weeklyMode`);
    return get(modeRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val(); // true または false を返す
        } else {
            console.log("No weekly mode data available");
            return null;
        }
    }).catch((error) => {
        console.error(error);
        return null;
    });
};

// ウイークリーモードのデータを保存する関数
export const saveWeeklyMode = (uid, isWeeklyMode) => {
    const modeRef = ref(database, `users/${uid}/settings/weeklyMode`);
    return set(modeRef, isWeeklyMode).then(() => {
        console.log("Weekly mode saved");
        return true;
    }).catch((error) => {
        console.error(error);
        return false;
    });
};
