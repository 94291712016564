// calendar.js
import { dateToStr } from "./Util";

export const buildCalendar = (date) => {
  const weeks = [];
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const numDays = lastDay.getDate();

  let week = [];
  let dateKeys = [];
  let dayIndex = 0;
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const now = new Date();

  // Add previous month's days
  const prevMonthLastDay = new Date(year, month, 0).getDate();
  const firstDayOfWeek = firstDay.getDay();
  for (let i = firstDayOfWeek - 1; i >= 0; i--) {
    const date = new Date(year, month - 1, prevMonthLastDay - i);
    week.push({
      date: date,
      isWeekEnd: false,
      isCurrentMonth: false,
      isToday: false,
      dayIndex: dayIndex,
    });
    dateKeys.push(dateToStr(date));
    dayIndex++;
  }

  // Add current month's days
  for (let day = 1; day <= numDays; day++) {
    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();
    const isWeekEnd = dayOfWeek === 0 || dayOfWeek === 6;
    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();

    week.push({
      date,
      isWeekEnd,
      isCurrentMonth: true,
      isToday: isToday,
      dayIndex: dayIndex,
    });
    dateKeys.push(dateToStr(date));

    dayIndex++;
    if (dayOfWeek === 6) {
      // 今週の判定
      const isCurrentWeek = week.some((day) => day.isToday);
      weeks.push({ days: week, isCurrentWeek });
      week = [];
    }
  }

  if (week.length > 0) {
    const isCurrentWeek = week.some((day) => day.isToday);
    weeks.push({ days: week, isCurrentWeek });
  }

  // Add next month's days
  const lastDayOfWeek = lastDay.getDay();
  week = [];
  for (let i = 1; i <= 6 - lastDayOfWeek; i++) {
    const date = new Date(year, month + 1, i);
    week.push({
      date: date,
      isWeekEnd: false,
      isCurrentMonth: false,
      isToday: false,
      dayIndex: dayIndex,
    });
    dateKeys.push(dateToStr(date));
    dayIndex++;
  }
  if (week.length > 0) {
    weeks.push({ days: week, isCurrentWeek: false });
  }

  return { month, year, weeks, weekDays, dateKeys };
};
