import React, { useState, useEffect } from "react";
import { saveMemoData, loadMemoData } from "./FirebaseUtil";

export function Memo({ isLoggedIn, uid }) {
  const [memoData, setMemoData] = useState(""); // 2. メモデータの状態を追加

  useEffect(() => {
    if (isLoggedIn) {
      loadMemoData(uid) // 3. メモデータの読み込み
        .then((data) => {
          setMemoData(data || "");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isLoggedIn, uid]);

  // メモデータの変更処理
  const handleMemoChange = (event) => {
    const newMemoData = event.currentTarget.value;
    setMemoData(newMemoData);
    saveMemoData(uid, newMemoData); // 4. メモデータの保存
  };

  return (
    <div className="memo">
      <label>
        <i className="las la-dragon"></i> One Time Note
      </label>
      <textarea
        placeholder="xxx"
        value={memoData}
        onChange={handleMemoChange}
      ></textarea>{" "}
      {/* 5. メモデータの表示と変更処理 */}
    </div>
  );
}
